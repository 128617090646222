<template>
  <el-dialog
    v-model="dialogVisible"
    width="800"
    destroy-on-close
    append-to-body
    :show-close="false"
    class="azgo-dialog"
  >
    <div px="6" py="8">
      <slot></slot>
    </div>

    <template #header="{ close, titleId }">
      <div px="6" h="18" flex="~ items-center justify-between" class="van-hairline--bottom">
        <div :id="titleId" text="lg #121212" font="500">
          {{ title }}
        </div>
        <NuxtIcon name="my-icon:close" class="#121212 azgo-hover-feedback text-2xl" @click="close" />
      </div>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    default: '',
  },
})

const dialogVisible = defineModel('show', {
  type: Boolean,
  default: false,
})
</script>

<style>
.azgo-dialog {
  --el-dialog-padding-primary: 0;
}
</style>
